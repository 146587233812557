// Docs: https://docs.esign.eu/books/development/page/front-end-template#bkmrk-basicgooglemap

import googleMapsStyles from '../../constants/googleMapsStyles';

const defaultMapData = {
  holderId: 'map',
  title: 'Esign',
  lat: 51.0802765,
  long: 4.4256653,
  externUrl:
    'https://www.google.com/maps/place/Huisartsenpraktijk+Tuinwijk/@51.0802765,4.4256653,17z/data=!4m15!1m8!3m7!1s0x47c3efe95824d039:0xcacf37c32138ecc5!2sTuinwijk+64,+2840+Rumst!3b1!8m2!3d51.0802765!4d4.4256653!16s%2Fg%2F11g8nmx8z1!3m5!1s0x47c3efec4e5f70a9:0xc807481c2ffdb5be!8m2!3d51.0802765!4d4.4256653!16s%2Fg%2F1tcx0ppg?entry=ttu',
};
export default class BasicGoogleMap {
  init(mapSettings = null) {
    const google = window.google || {};
    this.googleMaps = google.maps;
    this.baseUrl = window.baseUrl || '';

    // override default map data if param is set
    const mapData = mapSettings || defaultMapData;
    const holder = document.getElementById(mapData.holderId);

    if (holder) {
      const map = this.addMap(holder, mapData.lat, mapData.long);
      this.addMarker(map, mapData.lat, mapData.long, mapData.title, mapData.externUrl);
    }
  }

  addMap(holder, latitude, longitude) {
    const zoom = 15;
    const disable = true;
    const scroll = false;
    const styledMap = new this.googleMaps.StyledMapType(googleMapsStyles, {
      name: 'Styled Map',
    });
    const mapCenter = new this.googleMaps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
    };
    const map = new this.googleMaps.Map(holder, mapOptions);

    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');

    this.googleMaps.event.addDomListener(window, 'resize', () => {
      map.setCenter(mapCenter);
    });

    return map;
  }

  addMarker(map, latitude, longitude, title, externUrl) {
    const myLatlng = new this.googleMaps.LatLng(latitude, longitude);

    const markerIcon = {
      url: window.markerImg,
      size: new this.googleMaps.Size(100, 128),
      origin: new this.googleMaps.Point(0, 0),
      anchor: new this.googleMaps.Point(25, 64),
      scaledSize: new this.googleMaps.Size(50, 64),
    };

    const marker = new this.googleMaps.Marker({
      position: myLatlng,
      map,
      icon: markerIcon,
    });

    this.googleMaps.event.addListener(marker, 'click', () => {
      window.open(externUrl, '_blank');
    });

    return marker;
  }
}
